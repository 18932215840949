@font-face {
  font-family: DMSans;
  src: url('DMSans.ttf');
}
* {
	font-family: DMSans,sans-serif;
}
body {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-font-smoothing: antialiased;
}
body::-webkit-scrollbar {
  width: 0!important;
}
.scPage::-webkit-scrollbar {
  width: 0!important;
}
input, textarea, select, button, Input, Button, Select {
	outline: none!important;
  resize: none!important;
}
input, textarea {
  border: 1px solid #fff;
}
input:focus, textarea:focus {
  border: 1px solid #867e89;
}
input::placeholder, textarea::placeholder {
  color: #867e89;
}
select {
	cursor: pointer;
}
.error {
  border: 2px solid #9c0f2e!important;
}
.pointer {
  cursor: pointer;
}
